import KioskService from "@/services/KioskService"
import {Message} from "element-ui"
import {SERVER_ERROR_MESSAGE} from "@/utils/messageUtils"
import {DUPLICATE_CALL_SERVER_MESSAGE, NO_INTERNET_MESSAGE} from "@/services/messageService";
import {checkVw} from "@/services/internalService";
import {isServerErrorOrNoInternet} from "@/utils/Helpers";
import Vue from "vue";
import {getThumbnailVideo, KIOSK_STATUS} from "@/services/kioskComposable";

const state = {
	data: {
		listThumbnailObject: {},
		videoPreview: null,
		showModalForm: false,
		showModalPlayVideo: false,
		loading: false,
		errorDataItems: false,
		itemsMessageTable: null,
		items: [],
		total: 0,
		limit: 50,
		formDataArray: [],
		detailDataItem: {
			"id": 1,
			"file_type": "png",
			"file_path": "https://v1.pinimg.com/videos/mc/expMp4/04/1e/f8/041ef8cc318021b29c3ffb34676f79c1_t1.mp4",
			"status": "published"
		},
		columns: [
			{
				prop: "file",
				label: "File",
				minWidth: 200,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "status",
				label: "Status",
				width: 140,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "created_at",
				label: "Tanggal",
				width: 180,
				"class-name": "body-14 col-black-2",
			},
			{
				fixed: "right",
				prop: "action",
				label: "Aksi",
				width: checkVw(100),
				"class-name": "body-14 col-black-2",
			},
		],
	},
}

const mutations = {
	changeControlKioskVideo(state, payload) {
		state.data = Object.assign({}, state.data, payload)
	},
	changeControlKioskVideoLoading(state, payload) {
		state.data.loading = payload
	},
	setItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
	SetItemsMessageTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	UPDATE_ITEMS(state, payload) {
		const index = state.data.items.findIndex((item) => item.id === payload.id);

		if (index !== -1) {
			//state.data.items[index] = payload;
			Vue.set(state.data.items, index, payload);
		}
	},
	UPDATE_FORMDATA_ARRAY(state, payload) {
		state.data.formDataArray = payload
	},
	UPDATE_LIST_THUMBNAIL(state, payload) {
		state.data.listThumbnailObject[payload.key] = payload.value
	},

	DELETE_ITEMS(state, itemsId) {
		const index = state.data.items.findIndex((item) => item.id === itemsId);
		if (index !== -1) {
			state.data.items.splice(index, 1);
		}
	},
}

const actions = {

	async onShowModal({commit}, isOpen) {
		commit("changeControlKioskVideo", {
			showModalForm: isOpen
		})
	},

	async onShowModalPlayVideo({commit}, isOpen) {
		commit("changeControlKioskVideo", {
			showModalPlayVideo: isOpen
		})
	},

	async fetchVideoControlKiosk({commit, state}, paramsDeviceId) {

		const {
			limit, loading
		} = state.data

		if (loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

		const params = {
			limit: limit,
			page: 1,
		}

		try {
			commit("setItemsError", null)
			commit("changeControlKioskVideoLoading", true)
			const service = new KioskService()
			const result = await service.videoKioskByDeviceGet(paramsDeviceId, params)

			if (result.data && result.data.data) {
				await getThumbnailVideo(result.data.data)
				commit("changeControlKioskVideo", {
					items: result.data.data,
					itemsMessageTable: null,
					total: result.data.data.length
				})
			}
		} catch (e) {
			commit("changeControlKioskVideo", {
				itemsMessageTable: "Gagal Mengambil Data",
				items: [],
				total: 0
			})

			if (e.status >= 500) {
				commit("setItemsError", "Data Banner: " + SERVER_ERROR_MESSAGE)
				commit("SetItemsMessageTable", SERVER_ERROR_MESSAGE)
			} else if (e.code === "ERR_NETWORK") {
				commit("setItemsError", "Data Banner: CORS Error")
				commit("SetItemsMessageTable", NO_INTERNET_MESSAGE)
			} else if (e.status >= 400) {
				commit("SetItemsMessageTable", e.error)
				Message({
					type: "error",
					message: e.error,
				})
			}
		} finally {
			commit("changeControlKioskVideoLoading", false)
		}
	},

	/**
	 * @param context
	 * @param payload {
	 *   deviceId: is required,
	 *   fileBinary: is required
	 * }
	 * */
	async postVideoControlKiosk({commit}, payload) {

		const {deviceId, fileBinary} = payload

		try {
			commit("changeControlKioskVideoLoading", true)
			const service = new KioskService()
			await service.videoKioskByDevicePost(deviceId, fileBinary)
			return true
		} catch (e) {
			console.log(e)

			let m
			if (e.status === 0) {
				m = "0 | Periksa internet anda"
			} else if (e.status >= 500) {
				m = "500 | Layanan tidak tersedia"
			} else if (e.status === 413) {
				m = "413 | File Size Video, terlalu besar."
			} else if (e.status >= 400) {
				m = `${e.status} | ${e.error}`
			}

			return m
		} finally {
			commit("changeControlKioskVideoLoading", false)
		}
	},

	async putStatusVideoControlKiosk({commit}, payload) {

		const {fileVideoId, deviceId, status} = payload

		try {
			commit("changeControlKioskVideoLoading", true)
			const service = new KioskService()
			const result = await service.videoKioskByDevicePut(deviceId, fileVideoId, status)
			// update index
			commit("UPDATE_ITEMS", result.data.data)
		} catch (e) {
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					Message({
						type: "error",
						message: e.error,
					})
				}
			}
		} finally {
			commit("changeControlKioskVideoLoading", false)
		}
	},

	async deleteVideoControlKiosk({commit}, payload) {

		const {fileVideoId, deviceId} = payload

		try {
			commit("changeControlKioskVideoLoading", true)
			const service = new KioskService()
			await service.videoKioskByDeviceDelete(deviceId, fileVideoId)
			// hapus index
			commit("DELETE_ITEMS", fileVideoId)
		} catch (e) {
			if (!isServerErrorOrNoInternet(e)) {
				if (e.status >= 400) {
					commit("SetItemsMessageTable", e.error)
					Message({
						type: "error",
						message: e.error,
					})
				}
			}
		} finally {
			commit("changeControlKioskVideoLoading", false)
		}
	},
}

const getters = {
	countPublishedVideo: state => {
		return state.data.items.filter(item => item.status === KIOSK_STATUS.published).length
	},
	countUnPublishedVideo: state => {
		return state.data.items.filter(item => item.status === KIOSK_STATUS.unpublished).length
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
